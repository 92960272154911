body {
  color: #495057;
  background-color: #f5f5f5;
}

a {
  color: #0665d0;
}
a.link-fx::before {
  background-color: #0665d0;
}
a:hover {
  color: #03356d;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #212529;
}

.content-heading {
  border-bottom-color: #ebebeb;
}

hr {
  border-top-color: #ebebeb;
}

.text-primary {
  color: #343a40 !important;
}

a.text-primary.link-fx::before {
  background-color: #343a40;
}
a.text-primary:hover, a.text-primary:focus {
  color: #060708 !important;
}

.text-primary-dark {
  color: #1d2124 !important;
}

a.text-primary-dark.link-fx::before {
  background-color: #1d2124;
}
a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: black !important;
}

.text-primary-darker {
  color: #060708 !important;
}

a.text-primary-darker.link-fx::before {
  background-color: #060708;
}
a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: black !important;
}

.text-primary-light {
  color: #6d7a86 !important;
}

a.text-primary-light.link-fx::before {
  background-color: #6d7a86;
}
a.text-primary-light:hover, a.text-primary-light:focus {
  color: #3f474e !important;
}

.text-primary-lighter {
  color: #b2bac1 !important;
}

a.text-primary-lighter.link-fx::before {
  background-color: #b2bac1;
}
a.text-primary-lighter:hover, a.text-primary-lighter:focus {
  color: #7a8793 !important;
}

.text-body-bg {
  color: #f5f5f5 !important;
}

a.text-body-bg.link-fx::before {
  background-color: #f5f5f5;
}
a.text-body-bg:hover, a.text-body-bg:focus {
  color: #c2c2c2 !important;
}

.text-body-bg-light {
  color: #fafafa !important;
}

a.text-body-bg-light.link-fx::before {
  background-color: #fafafa;
}
a.text-body-bg-light:hover, a.text-body-bg-light:focus {
  color: #c7c7c7 !important;
}

.text-body-bg-dark {
  color: #ebebeb !important;
}

a.text-body-bg-dark.link-fx::before {
  background-color: #ebebeb;
}
a.text-body-bg-dark:hover, a.text-body-bg-dark:focus {
  color: #b8b8b8 !important;
}

.text-body-color {
  color: #495057 !important;
}

a.text-body-color.link-fx::before {
  background-color: #495057;
}
a.text-body-color:hover, a.text-body-color:focus {
  color: #1a1d20 !important;
}

.text-body-color-dark {
  color: #212529 !important;
}

a.text-body-color-dark.link-fx::before {
  background-color: #212529;
}
a.text-body-color-dark:hover, a.text-body-color-dark:focus {
  color: black !important;
}

.text-body-color-light {
  color: #ebebeb !important;
}

a.text-body-color-light.link-fx::before {
  background-color: #ebebeb;
}
a.text-body-color-light:hover, a.text-body-color-light:focus {
  color: #b8b8b8 !important;
}

.text-dual {
  color: #1d2124 !important;
}

a.text-dual.link-fx::before {
  background-color: #1d2124;
}
a.text-dual:hover, a.text-dual:focus {
  color: black !important;
}

.page-header-dark #page-header .text-dual,
.sidebar-dark #sidebar .text-dual {
  color: #ebebeb !important;
}
.page-header-dark #page-header a.text-dual.link-fx::before,
.sidebar-dark #sidebar a.text-dual.link-fx::before {
  background-color: #ebebeb;
}
.page-header-dark #page-header a.text-dual:hover, .page-header-dark #page-header a.text-dual:focus,
.sidebar-dark #sidebar a.text-dual:hover,
.sidebar-dark #sidebar a.text-dual:focus {
  color: #b8b8b8 !important;
}

.bg-primary {
  background-color: #343a40 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1d2124 !important;
}

.bg-primary-op {
  background-color: rgba(52, 58, 64, 0.75) !important;
}

a.bg-primary-op:hover, a.bg-primary-op:focus,
button.bg-primary-op:hover,
button.bg-primary-op:focus {
  background-color: rgba(29, 33, 36, 0.75) !important;
}

.bg-primary-dark {
  background-color: #1d2124 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #060708 !important;
}

.bg-primary-dark-op {
  background-color: rgba(29, 33, 36, 0.8) !important;
}

a.bg-primary-dark-op:hover, a.bg-primary-dark-op:focus,
button.bg-primary-dark-op:hover,
button.bg-primary-dark-op:focus {
  background-color: rgba(6, 7, 8, 0.8) !important;
}

.bg-primary-darker {
  background-color: #060708 !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: black !important;
}

.bg-primary-light {
  background-color: #6d7a86 !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #56606a !important;
}

.bg-primary-lighter {
  background-color: #b2bac1 !important;
}

a.bg-primary-lighter:hover, a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
  background-color: #96a0aa !important;
}

.bg-body {
  background-color: #f5f5f5 !important;
}

a.bg-body:hover, a.bg-body:focus,
button.bg-body:hover,
button.bg-body:focus {
  background-color: gainsboro !important;
}

.bg-body-light {
  background-color: #fafafa !important;
}

a.bg-body-light:hover, a.bg-body-light:focus,
button.bg-body-light:hover,
button.bg-body-light:focus {
  background-color: #e1e1e1 !important;
}

.bg-body-dark {
  background-color: #ebebeb !important;
}

a.bg-body-dark:hover, a.bg-body-dark:focus,
button.bg-body-dark:hover,
button.bg-body-dark:focus {
  background-color: #d1d1d1 !important;
}

.bg-header-light {
  background-color: #fff !important;
}

a.bg-header-light:hover, a.bg-header-light:focus,
button.bg-header-light:hover,
button.bg-header-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-header-dark {
  background-color: #343a40 !important;
}

a.bg-header-dark:hover, a.bg-header-dark:focus,
button.bg-header-dark:hover,
button.bg-header-dark:focus {
  background-color: #1d2124 !important;
}

.bg-sidebar-light {
  background-color: #fff !important;
}

a.bg-sidebar-light:hover, a.bg-sidebar-light:focus,
button.bg-sidebar-light:hover,
button.bg-sidebar-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-sidebar-dark {
  background-color: #35393e !important;
}

a.bg-sidebar-dark:hover, a.bg-sidebar-dark:focus,
button.bg-sidebar-dark:hover,
button.bg-sidebar-dark:focus {
  background-color: #1d2023 !important;
}

.bg-gd-primary {
  background: #343a40 linear-gradient(135deg, #343a40 0%, #56606a 100%) !important;
}

.btn-link {
  color: #0665d0;
}
.btn-link:hover {
  color: #03356d;
}

.btn-primary {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-primary:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-hero-primary {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  background-color: #343a40;
  border: none;
  box-shadow: 0 0.125rem 0.75rem rgba(18, 20, 22, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
}
.btn-hero-primary:hover {
  color: #fff;
  background-color: #4b545c;
  box-shadow: 0 0.375rem 0.75rem rgba(18, 20, 22, 0.4);
  transform: translateY(-1px);
}
.btn-hero-primary:focus, .btn-hero-primary.focus {
  color: #fff;
  background-color: #4b545c;
  box-shadow: 0 0.125rem 0.75rem rgba(18, 20, 22, 0.25);
}
.btn-hero-primary.disabled, .btn-hero-primary:disabled {
  color: #fff;
  background-color: #343a40;
  box-shadow: 0 0.125rem 0.75rem rgba(18, 20, 22, 0.25);
  transform: translateY(0);
}
.btn-hero-primary:not(:disabled):not(.disabled):active, .btn-hero-primary:not(:disabled):not(.disabled).active, .show > .btn-hero-primary.dropdown-toggle {
  color: #fff;
  background-color: #121416;
  box-shadow: 0 0.125rem 0.75rem rgba(18, 20, 22, 0.25);
  transform: translateY(0);
}
.btn-hero-primary:not(:disabled):not(.disabled):active:focus, .btn-hero-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-hero-primary.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(18, 20, 22, 0.25);
}

.btn-hero-lg {
  padding: 0.875rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-hero-sm {
  padding: 0.375rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-dual {
  color: #16181a;
  background-color: transparent;
  border-color: transparent;
}
.btn-dual:hover, .btn-dual:focus, .btn-dual.focus {
  color: #16181a;
  background-color: gainsboro;
  border-color: gainsboro;
  box-shadow: none;
}
.btn-dual.disabled, .btn-dual:disabled {
  background-color: transparent;
  border-color: transparent;
}
.btn-dual:active, .btn-dual.active {
  color: #16181a;
  background-color: transparent;
  border-color: transparent;
}
.btn-dual:not(:disabled):not(.disabled):active, .btn-dual:not(:disabled):not(.disabled).active, .show > .btn-dual.dropdown-toggle {
  color: #16181a;
  background-color: gainsboro;
  border-color: gainsboro;
}

.page-header-dark #page-header .btn-dual,
.sidebar-dark #sidebar .btn-dual {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.page-header-dark #page-header .btn-dual:hover, .page-header-dark #page-header .btn-dual:focus, .page-header-dark #page-header .btn-dual.focus,
.sidebar-dark #sidebar .btn-dual:hover,
.sidebar-dark #sidebar .btn-dual:focus,
.sidebar-dark #sidebar .btn-dual.focus {
  color: #fff;
  background-color: #1d2124;
  border-color: #1d2124;
  box-shadow: none;
}
.page-header-dark #page-header .btn-dual.disabled, .page-header-dark #page-header .btn-dual:disabled,
.sidebar-dark #sidebar .btn-dual.disabled,
.sidebar-dark #sidebar .btn-dual:disabled {
  background-color: transparent;
  border-color: transparent;
}
.page-header-dark #page-header .btn-dual:active, .page-header-dark #page-header .btn-dual.active,
.sidebar-dark #sidebar .btn-dual:active,
.sidebar-dark #sidebar .btn-dual.active {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.page-header-dark #page-header .btn-dual:not(:disabled):not(.disabled):active, .page-header-dark #page-header .btn-dual:not(:disabled):not(.disabled).active, .show > .page-header-dark #page-header .btn-dual.dropdown-toggle,
.sidebar-dark #sidebar .btn-dual:not(:disabled):not(.disabled):active,
.sidebar-dark #sidebar .btn-dual:not(:disabled):not(.disabled).active,
.show > .sidebar-dark #sidebar .btn-dual.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #1d2124;
}

.alert-primary {
  color: #1d2124;
  background-color: #b2bac1;
  border-color: #b2bac1;
}
.alert-primary hr {
  border-top-color: #a4adb5;
}
.alert-primary .alert-link {
  color: #060708;
}

.badge-primary {
  color: #fff;
  background-color: #343a40;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.progress-bar {
  background-color: #343a40;
}

.nav-link {
  color: #495057;
}
.nav-link:hover, .nav-link:focus {
  color: #343a40;
}

.nav-pills .nav-link:hover, .nav-pills .nav-link:focus {
  background-color: #f5f5f5;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #343a40;
}

.nav-tabs {
  border-bottom-color: #ebebeb;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #ebebeb #ebebeb #ebebeb;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: #ebebeb #ebebeb #fff;
}

.nav-tabs-block {
  background-color: #fafafa;
}
.nav-tabs-block .nav-link {
  border-color: transparent;
}
.nav-tabs-block .nav-link:hover, .nav-tabs-block .nav-link:focus {
  color: #343a40;
  background-color: #f5f5f5;
  border-color: transparent;
}
.nav-tabs-block .nav-link.active,
.nav-tabs-block .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: transparent;
}

.nav-tabs-alt {
  border-bottom-color: #ebebeb;
}
.nav-tabs-alt .nav-link {
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-alt .nav-link:hover, .nav-tabs-alt .nav-link:focus {
  color: #343a40;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #343a40;
}
.nav-tabs-alt .nav-link.active,
.nav-tabs-alt .nav-item.show .nav-link {
  color: #495057;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #343a40;
}

.nav-items a {
  border-bottom-color: #f5f5f5;
}
.nav-items a:hover {
  background-color: #fafafa;
}
.nav-items a:active {
  background-color: #f5f5f5;
}
.nav-items > li:last-child > a {
  border-bottom: none;
}

.page-item.active .page-link {
  background-color: #343a40;
  border-color: #343a40;
}

.page-link {
  color: #495057;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.page-link:hover {
  color: #495057;
  background-color: #d1d1d1;
  border-color: #d1d1d1;
}
.page-link:focus {
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.list-group-item-action {
  color: #495057;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  background-color: #fafafa;
}
.list-group-item-action:active {
  color: #495057;
  background-color: #ebebeb;
}

.list-group-item {
  border-color: #ebebeb;
}
.list-group-item.active {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.popover {
  border-color: #ebebeb;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before {
  border-top-color: #ebebeb;
}
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #fff;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before {
  border-right-color: #ebebeb;
}
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #fff;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #ebebeb;
}
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #fff;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before {
  border-left-color: #ebebeb;
}
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #fff;
}

.modal-header {
  border-bottom-color: #ebebeb;
}

.modal-footer {
  border-top-color: #ebebeb;
}

.dropdown-menu {
  border-color: #ebebeb;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f5f5f5;
}

.dropdown-item {
  color: #495057;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #212529;
  background-color: #f5f5f5;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #343a40;
}

.dropdown-header {
  color: #212529;
}

.table th,
.table td {
  border-top-color: #ebebeb;
}
.table thead th {
  border-bottom-color: #ebebeb;
}
.table tbody + tbody {
  border-top-color: #ebebeb;
}

.table-bordered,
.table-bordered th,
.table-bordered td {
  border-color: #ebebeb;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f1f1f1;
}

.table-hover .table-active:hover {
  background-color: #e4e4e4;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e4e4e4;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b2bac1;
}

.table-hover .table-primary:hover {
  background-color: #a4adb5;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a4adb5;
}

.table .thead-dark th {
  background-color: #1d2124;
  border-color: #1d2124;
}
.table .thead-light th {
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.form-control {
  color: #495057;
  background-color: #fff;
  border-color: #e1e1e1;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #6d7a86;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-control-primary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #343a40;
}
.custom-control-primary .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}
.custom-control-primary .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #626d78;
}
.custom-control-primary .custom-control-label::before {
  background-color: #ebebeb;
}
.custom-control-primary.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #343a40;
}
.custom-control-primary.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #343a40;
}
.custom-control-primary.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #343a40;
}
.custom-control-primary.custom-block .custom-block-indicator {
  background-color: #343a40;
}
.custom-control-primary.custom-block .custom-control-input:checked ~ .custom-control-label {
  background-color: #ebebeb;
  border-color: #343a40;
}
.custom-control-primary.custom-block .custom-control-input:focus ~ .custom-control-label {
  box-shadow: 0 0 0.75rem rgba(52, 58, 64, 0.25);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #343a40;
  border-color: #343a40;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #b2bac1;
}

.custom-select {
  border-color: #e1e1e1;
}
.custom-select:focus {
  border-color: #6d7a86;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(109, 122, 134, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #6d7a86;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}
.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #6d7a86;
}

.custom-file-label {
  color: #495057;
  background-color: #fff;
  border-color: #e1e1e1;
}
.custom-file-label::after {
  color: #495057;
  background-color: #ebebeb;
  border-left-color: #e1e1e1;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #3cd549;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(60, 213, 73, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #3cd549;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #3cd549;
  box-shadow: 0 0 0 0.2rem rgba(60, 213, 73, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #3cd549;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #3cd549;
  box-shadow: 0 0 0 0.2rem rgba(60, 213, 73, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #3cd549;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #3cd549;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #3cd549;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #66de70;
  background-color: #66de70;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(60, 213, 73, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #3cd549;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #3cd549;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #3cd549;
  box-shadow: 0 0 0 0.2rem rgba(60, 213, 73, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #d34d80;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(211, 77, 128, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d34d80;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #d34d80;
  box-shadow: 0 0 0 0.2rem rgba(211, 77, 128, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d34d80;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #d34d80;
  box-shadow: 0 0 0 0.2rem rgba(211, 77, 128, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d34d80;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d34d80;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #d34d80;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #dd769d;
  background-color: #dd769d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(211, 77, 128, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d34d80;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d34d80;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d34d80;
  box-shadow: 0 0 0 0.2rem rgba(211, 77, 128, 0.25);
}

.form-control.form-control-alt {
  border-color: #f5f5f5;
  background-color: #f5f5f5;
}
.form-control.form-control-alt:focus {
  border-color: #ebebeb;
  background-color: #ebebeb;
  box-shadow: none;
}
.form-control.form-control-alt.is-valid {
  border-color: #e0f8e2;
  background-color: #e0f8e2;
}
.form-control.form-control-alt.is-valid:focus {
  border-color: #cbf4ce;
  background-color: #cbf4ce;
}
.form-control.form-control-alt.is-invalid {
  border-color: white;
  background-color: white;
}
.form-control.form-control-alt.is-invalid:focus {
  border-color: white;
  background-color: white;
}

.input-group-text {
  color: #495057;
  background-color: #f5f5f5;
  border-color: #e1e1e1;
}

.input-group-text.input-group-text-alt {
  background-color: #ebebeb;
  border-color: #ebebeb;
}

.border {
  border-color: #ebebeb !important;
}

.border-top {
  border-top-color: #ebebeb !important;
}

.border-right {
  border-right-color: #ebebeb !important;
}

.border-bottom {
  border-bottom-color: #ebebeb !important;
}

.border-left {
  border-left-color: #ebebeb !important;
}

.border-primary-light {
  border-color: #aed5ef !important;
}

.border-primary-lighter {
  border-color: #eef6fc !important;
}

.border-primary-dark {
  border-color: #449eda !important;
}

.border-primary-darker {
  border-color: #2377ae !important;
}

.border-success-light {
  border-color: #a1eba7 !important;
}

.border-info-light {
  border-color: #a5ccf0 !important;
}

.border-warning-light {
  border-color: #fde9ad !important;
}

.border-danger-light {
  border-color: #ebafc6 !important;
}

.border-primary {
  border-color: #6eb4e2 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #3cd549 !important;
}

.border-info {
  border-color: #3c90df !important;
}

.border-warning {
  border-color: #fbca35 !important;
}

.border-danger {
  border-color: #d34d80 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-primary {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white-op {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-black-op {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

#page-header {
  background-color: #fff;
}

#sidebar {
  background-color: #fff;
}

#side-overlay {
  background-color: #fff;
}

#page-container.page-header-dark #page-header {
  color: #d6d6d6;
  background-color: #343a40;
}
#page-container.page-header-glass #page-header {
  background-color: transparent;
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll #page-header {
  background-color: #fff;
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll.page-header-dark #page-header {
  background-color: #343a40;
}
#page-container.sidebar-dark #sidebar {
  color: #ebebeb;
  background-color: #35393e;
}

.block {
  box-shadow: 0 2px 6px rgba(235, 235, 235, 0.4);
}

.block-header-default {
  background-color: #fafafa;
}

.block.block-bordered {
  border-color: #ebebeb;
}
.block.block-themed > .block-header {
  background-color: #343a40;
}

.block.block-mode-loading::after {
  color: #1d2124;
  box-shadow: 0 0 0.75rem 0.75rem rgba(29, 33, 36, 0.05);
}
.block.block-mode-loading.block-mode-hidden::after {
  box-shadow: 0 0 0.5rem 0.5rem rgba(29, 33, 36, 0.05);
}
.block.block-mode-loading.block-mode-loading-dark::after {
  background-color: #1d2124;
}

a.block {
  color: #495057;
}
a.block:hover {
  color: #495057;
}
a.block.block-link-pop:hover {
  box-shadow: 0 0.5rem 2rem #e1e1e1;
}
a.block.block-link-pop:active {
  box-shadow: 0 0.25rem 0.75rem #f2f2f2;
}
a.block.block-link-shadow:hover {
  box-shadow: 0 0 2.25rem #e1e1e1;
}
a.block.block-link-shadow:active {
  box-shadow: 0 0 1.125rem #ebebeb;
}

.block.block-fx-shadow {
  box-shadow: 0 0 2.25rem #e1e1e1;
}
.block.block-fx-pop {
  box-shadow: 0 0.5rem 2rem #e1e1e1;
}

.btn-block-option {
  color: #343a40;
}
.btn-block-option:hover {
  color: #6d7a86;
}
a.btn-block-option:focus,
.active > a.btn-block-option,
.show > button.btn-block-option {
  color: #6d7a86;
}

.btn-block-option:active {
  color: #b2bac1;
}

#page-loader {
  background-color: #343a40;
}

.nav-main-heading {
  color: #869099;
}

.nav-main-link {
  color: #495057;
}
.nav-main-link .nav-main-link-icon {
  color: #6d7a86;
}
.nav-main-link:hover, .nav-main-link.active {
  color: #000;
  background-color: #e9ecef;
}

.nav-main-submenu {
  background-color: #f8f9fa;
}
.nav-main-submenu .nav-main-link {
  color: rgba(73, 80, 87, 0.75);
}
.nav-main-submenu .nav-main-link:hover, .nav-main-submenu .nav-main-link.active {
  color: #000;
  background-color: transparent;
}

.nav-main-item.open > .nav-main-link-submenu {
  color: #000;
  background-color: #e9ecef;
}

.nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

@media (min-width: 992px) {
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu {
    color: #000;
    background-color: #e9ecef;
  }
}
.nav-main-dark .nav-main-heading,
.sidebar-dark #sidebar .nav-main-heading,
.page-header-dark #page-header .nav-main-heading {
  color: #b8b8b8;
}
.nav-main-dark .nav-main-link,
.sidebar-dark #sidebar .nav-main-link,
.page-header-dark #page-header .nav-main-link {
  color: #ebebeb;
}
.nav-main-dark .nav-main-link > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link > .nav-main-link-icon {
  color: #b2bac1;
}
.nav-main-dark .nav-main-link:hover, .nav-main-dark .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-link.active,
.page-header-dark #page-header .nav-main-link:hover,
.page-header-dark #page-header .nav-main-link.active {
  color: #fff;
  background-color: #1d2023;
}
.nav-main-dark .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-submenu,
.page-header-dark #page-header .nav-main-submenu {
  background-color: #292c30;
}
.nav-main-dark .nav-main-submenu .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-link {
  color: rgba(235, 235, 235, 0.75);
}
.nav-main-dark .nav-main-submenu .nav-main-link:hover, .nav-main-dark .nav-main-submenu .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link.active,
.page-header-dark #page-header .nav-main-submenu .nav-main-link:hover,
.page-header-dark #page-header .nav-main-submenu .nav-main-link.active {
  color: #fff;
  background-color: transparent;
}
.nav-main-dark .nav-main-item.open > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-link-submenu {
  color: #fff;
  background-color: #1d2023;
}
.nav-main-dark .nav-main-item.open > .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-submenu {
  background-color: #292c30;
}
.nav-main-dark .nav-main-submenu .nav-main-item.open .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-item.open .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

@media (min-width: 992px) {
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu {
    color: #fff;
    background-color: #1d2023;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu {
    background-color: #292c30;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link {
    background-color: transparent;
  }
}
.nav-items a {
  border-bottom-color: #f5f5f5;
}
.nav-items a:hover {
  background-color: #fafafa;
}

.list-activity > li {
  border-bottom-color: #f5f5f5;
}

.timeline-event-icon {
  box-shadow: 0 0.375rem 1.5rem #e1e1e1;
}

.ribbon-light .ribbon-box {
  color: #495057;
  background-color: #ebebeb;
}
.ribbon-light.ribbon-bookmark .ribbon-box::before {
  border-color: #ebebeb;
  border-left-color: transparent;
}
.ribbon-light.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #ebebeb;
  border-right-color: transparent;
}

.ribbon-primary .ribbon-box {
  color: #fff;
  background-color: #343a40;
}
.ribbon-primary.ribbon-bookmark .ribbon-box::before {
  border-color: #343a40;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #343a40;
  border-right-color: transparent;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #343a40;
  border-color: #343a40;
}

.cke_chrome {
  border-color: #ebebeb !important;
}

.cke_top {
  border-bottom-color: #ebebeb !important;
  background: #fafafa !important;
}

.cke_bottom {
  border-top-color: #ebebeb !important;
  background: #fafafa !important;
}

.dropzone {
  background-color: #fafafa;
  border-color: #e1e1e1;
}
.dropzone .dz-message {
  color: #495057;
}
.dropzone:hover {
  background-color: #fff;
  border-color: #343a40;
}
.dropzone:hover .dz-message {
  color: #343a40;
}

.fc-bootstrap4 a.fc-event:not([href]):not([tabindex]) {
  color: #212529;
}
.fc-bootstrap4 .fc-event {
  color: #212529;
}
.fc-bootstrap4 .fc-event:hover {
  color: #495057;
}
.fc-bootstrap4 thead th.fc-day-header {
  background-color: #fafafa;
}
.fc-bootstrap4 th,
.fc-bootstrap4 td,
.fc-bootstrap4 thead,
.fc-bootstrap4 tbody,
.fc-bootstrap4 .fc-divider,
.fc-bootstrap4 .fc-row,
.fc-bootstrap4 .fc-content,
.fc-bootstrap4 .fc-popover,
.fc-bootstrap4 .fc-list-view,
.fc-bootstrap4 .fc-list-heading td,
.fc-bootstrap4 .fc-list-table.table {
  border-color: #ebebeb;
}
.fc-bootstrap4 .fc-divider,
.fc-bootstrap4 .fc-list-heading td,
.fc-bootstrap4 .fc-popover .fc-header {
  background: #f5f5f5;
}
.fc-bootstrap4 .fc-today {
  background: #fafafa;
}

.irs.irs--round .irs-min,
.irs.irs--round .irs-max,
.irs.irs--round .irs-line,
.irs.irs--round .irs-grid-pol {
  background: #f5f5f5;
}
.irs.irs--round .irs-handle {
  border-color: #343a40;
}
.irs.irs--round .irs-from:before,
.irs.irs--round .irs-to:before,
.irs.irs--round .irs-single:before {
  border-top-color: #343a40;
}
.irs.irs--round .irs-bar,
.irs.irs--round .irs-from,
.irs.irs--round .irs-to,
.irs.irs--round .irs-single {
  background: #343a40;
}

.select2-container--default .select2-selection--single {
  border-color: #e1e1e1;
}
.select2-container--default .select2-selection--multiple {
  border-color: #e1e1e1;
}
.select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--single, .select2-container--default.select2-container--open .select2-selection--multiple, .select2-container--default.select2-container--open .select2-selection--single {
  border-color: #6d7a86;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #343a40;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #e1e1e1;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #343a40;
}
.select2-container--default .select2-dropdown .select2-search__field:focus {
  border-color: #6d7a86;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.25);
}

.simplebar-scrollbar::before {
  background: #060708;
}
.sidebar-dark #sidebar .simplebar-scrollbar::before {
  background: #b2bac1;
}

.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  color: #1d2124;
}

.editor-toolbar {
  border-color: #ebebeb;
  background-color: #fafafa;
}

.CodeMirror {
  border-color: #ebebeb;
}

.note-editor.note-frame {
  border-color: #ebebeb;
}
.note-editor.note-frame .note-toolbar {
  background-color: #fafafa;
  border-bottom-color: #ebebeb;
}
.note-editor.note-frame .note-statusbar {
  border-top-color: #ebebeb;
  background-color: #fafafa;
}

.dd-handle {
  color: #495057;
  background: #fafafa;
  border-color: #ebebeb;
}
.dd-handle:hover {
  color: #212529;
}

.dd-empty,
.dd-placeholder {
  border-color: #060708;
  background: #b2bac1;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  border-color: #343a40;
  background: #343a40;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #343a40;
}